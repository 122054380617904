import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
// routes
// redux
import { store, persistor } from "./redux/store";
// theme
import ThemeConfig from "./theme";
// components
import LoadingScreen from "./components/LoadingScreen";
import Routes from "./routes/Routes";
// import { stubFalse } from "lodash";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            <Routes />
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
