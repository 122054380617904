// material
import { Chip, useTheme } from '@material-ui/core';
// utils
// motor JS

import { useSelections, useButton } from '@motor-js/engine';

export default function QlikSelections() {
  const { selections } = useSelections();
  const { select } = useButton();
  const theme = useTheme();

  return selections === null
    ? null
    : selections.map((item, index) => (
        <Chip
          key={index}
          label={item.qSelected}
          onDelete={
            ![
              'Value',
              'Volume',
              'Last Year',
              'Last Last Year',
              'Budget',
            ].includes(item.qSelected)
              ? () => select(`${item.qSelected},`, item.qField)
              : null
          }
          onClick={() => select(`${item.qSelected},`, item.qField)}
          style={{
            backgroundColor:
              item.qField === 'AsOfWeek' ? theme.palette.primary.lighter : null,
          }}
        />
      ));
}
