// scroll bar
import 'simplebar/src/simplebar.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import App from './App';

// load motor js framework

// import motor-js wrapper

import { Motor } from '@motor-js/engine';

// ----------------------------------------------------------------------

ReactDOM.render(
  <Motor
    config={{
      //Enter your app config here..
      host: 'repa8nuc00b2vcz.eu.qlikcloud.com',
      secure: true,
      port: null,
      prefix: '',
      appId: 'be0bb2c1-6616-40e1-95f6-96747d27f2bc',
      webIntId: '9ChazEO02jkNEjOet9CEaHcEeE-_BcDP',
      qcs: true,
      licenseKey: 'U2FsdGVkX19vjmrWbhnS5zEHEGj2PxXnorHHCxZ55lg=',
    }}
    body={'Please login to access your analytics'}
    buttonColor={'#3366FF'}
    buttonFontColor={'white'}
    header={'Sign in to Autone'}
  >
    <App />
  </Motor>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
