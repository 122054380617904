// material
import { Typography, Box } from "@material-ui/core";
// ----------------------------------------------------------------------

export default function PoweredByAutone() {
  return (
    <Typography
      variant="caption"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      Powered by{" "}
      <Box
        component="img"
        alt="logo"
        src="static/logo/autone_icon.png"
        height={20}
        sx={{ ml: 1 }}
      />
    </Typography>
  );
}
