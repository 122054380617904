import { Icon } from "@iconify/react";
import { useState } from "react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import {
  experimentalStyled as styled,
  alpha,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Input,
  Slide,
  InputAdornment,
  ClickAwayListener,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
// components
import { MIconButton } from "../@material-extend";
import { useSearch, useButton } from "@motor-js/engine";
import _ from "lodash";
import ReactGA from "react-ga";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: APPBAR_MOBILE,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const SearchbarresultsStyle = styled("div")(({ theme }) => ({
  color: "black",
  cursor: "pointer",
  top: APPBAR_MOBILE,
  [theme.breakpoints.up("md")]: {
    top: APPBAR_DESKTOP,
    padding: theme.spacing(0, 0),
  },
  width: "100%",
  position: "absolute",
  backgroundColor: "white",
  zIndex: 999999,
  boxShadow: theme.customShadows.z8,
  maxHeight: "200px",
  overflowY: "scroll",
  padding: "0px",
  borderRadius: "5px",
  left: "0px",
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&.isActiveRoot": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
    },
  },
  "&.isActiveSub": {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    "& .subIcon:before": {
      transform: "scale(2)",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  block: {
    display: "block",
  },
  listItemText: {
    fontSize: "0.7em",
  },
  nested: {
    paddingLeft: theme.spacing(0, 1),
    width: "100%",
    textTransform: "capitalize",
    fontSize: "14px",
    borderRadius: "3px",
    "&:hover": {
      background: "#f5f5f5",
    },
  },
  label: {
    ...theme.typography.body2,
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchValue("");
  };

  const [searchValue, setSearchValue] = useState("");
  const [qCount] = useState(10);
  const [qGroupItemCount] = useState(10);

  const { groupResults } = useSearch({
    searchValue,
    qCount,
    qGroupItemCount,
  });

  const { selectValues } = useButton();

  const handleSelect = (searchItem) => {
    const dimension = searchItem.dimension;
    const selections = _.map(searchItem.value, "qText");

    selectValues(selections, dimension, true);

    ReactGA.event({
      category: "Search Filter",
      action: "Search selected",
      label: `${dimension} | ${selections}`,
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              onChange={(event) => setSearchValue(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: "fontWeightBold" }}
            />
            {groupResults.length > 0 ? (
              <SearchbarresultsStyle>
                <List className={classes.root}>
                  {groupResults.map((searchItem) => (
                    <ListItemStyle
                      button
                      disableGutters
                      key={searchItem.id}
                      className={classes.block}
                      onClick={() => handleSelect(searchItem)}
                    >
                      <Typography
                        component="div"
                        variant="caption"
                        display="block"
                        noWrap
                      >
                        {searchItem.dimension}
                      </Typography>
                      <Typography
                        component="div"
                        variant="caption"
                        display="block"
                        noWrap
                      >
                        {_.map(searchItem.value, "qText").join(",")}
                      </Typography>
                    </ListItemStyle>
                  ))}
                </List>{" "}
              </SearchbarresultsStyle>
            ) : null}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
